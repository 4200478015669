<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Document Templates</h1>
      <div class="btns-container">
        <VasionButton
          id="new-template-button"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="newDocument"
        >
          New Document Template
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows.Values"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="handleRowSelect"
        />
      </div>
    </div>
    <VasionGeneralModal
      id="delete-document-template-dialog"
      :message="deleteMessage"
      :sync="showDeleteDialog"
      modalType="message"
      buttonGroup="center"
      rejectButtonText="no"
      confirmButtonText="yes"
      @confirmButtonClick="deleteDocumentTemplate"
      @noButtonClick="cancelDelete"
    />

    <VasionSnackbar
      id="document-template-snack"
      :showSnackbarBool="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AllEmailTemplates',
  components: {
    Loading,
  },
  data: function () {
    return {
      ColumnValues: [
        'templateID',
        '_VasionIcon_',
        'Name',
        'Object',
        '_VasionEllipsisButton_',
      ],
      deleteMessage: '',
      hiddenColumns: ['templateID'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      selectedDocument: {
        name: '',
        id: null,
      },
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    templateList() { return this.$store.state.common.documentTemplates },
  },
  async created() {
    await Promise.all([
      this.refreshTable(),
      this.$store.dispatch('common/getDocumentTemplates'),
      this.$store.dispatch('security/setEllipsisButtonConfig', 'document-templates'),
    ])
  },
  methods: {
    cancelDelete() { this.showDeleteDialog = false },
    async deleteDocumentTemplate() {
      const result = await this.$store.dispatch('admin/deleteDocumentTemplate', this.selectedDocument.id)

      this.snackbarTitle = result?.status === 200 ? 'Delete Successful' : 'Error Deleting'
      this.snackbarSubTitle = result?.status === 200 ? `Deleted "${this.selectedDocument.name}".` : `There was an error deleting "${this.selectedDocument.name}".\nPlease contact your administrator if the problem persists.`
      this.snackbarImage = result?.status === 200
      this.showSnackbarBool = true

      if (result?.status === 200) {
        this.refreshTable()
      }

      this.showDeleteDialog = false
    },
    async copyDocumentTemplate() {
      this.isLoading = true
      const result = await this.$store.dispatch('admin/copyDocumentTemplate', this.selectedDocument.id)

      this.snackbarTitle = result?.status === 200 ? 'Copy Successful' : 'Error copying document template'
      this.snackbarSubTitle = result?.status === 200 ? `Copied "${this.selectedDocument.name}".` : `There was an error copying "${this.selectedDocument.name}".\nPlease contact your administrator if the problem persists.`
      this.snackbarImage = result?.status === 200
      this.showSnackbarBool = true

      this.isLoading = false
      if (result?.status === 200) {
        this.navigateToDocumentTemplate(result.data.documentTemplateID)
      }
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, , name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-document-templates-button':
            this.navigateToDocumentTemplate(payload.item.Values[0])
            break;
          case 'delete-document-templates-button':
            this.setSelectedDocument(name, id)
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.showDeleteDialog = true
            break;
          case 'copy-document-templates-button':
            this.setSelectedDocument(name, id)
            this.copyDocumentTemplate()
            break;
          default:
            break;
        }
      }
    },
    handleRowSelect(data) {
      if (!data || !data.Values || data.Values.length <= 0) {
        return
      }

      this.navigateToDocumentTemplate(data.Values[0])
    },
    navigateToDocumentTemplate(documentTemplateID) {
      if (!documentTemplateID) {
        documentTemplateID = 0
      }

      this.$router.push({ name: 'EditDocumentTemplate', params: { templateId: documentTemplateID } })
    },
    newDocument() { this.navigateToDocumentTemplate(0) },
    async refreshTable() {
      this.isLoading = true

      await this.$store.dispatch('common/getDocumentTemplates')

      this.tableData = {
        Rows: {
          Values: [],
        },
      }

      this.tableData.Rows.Values = this.templateList.map((templateElement) => {
        const dataRow = [
          templateElement.documentTemplateID,
          'VasionDocumentTemplateIcon',
          templateElement.name,
          templateElement.objectName,
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.isLoading = false
    },
    setSelectedDocument(name, id) {
      this.selectedDocument.name = name
      this.selectedDocument.id = id
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
